import React from "react";
import ProductMenu from "../components/ProductMenu";
import PopularProductList from "../components/PopularProductList/PopularProductList";
import DeliveryAddress from "../components/DeliveryAddress/DeliveryAddress";
import ProgressIndicator from "../components/ProgressIndicator/ProgressIndicator";
import { useRestaurantSelector } from "../store/useSelectors";
import stickerLittleMoleSearch from "../assets/lottie/little_mole/search.json";
import { ErrorContainer } from "../components/ErrorHandler";

const MenuPage = (props) => {
  const { categories, isLoaded } = useRestaurantSelector();

  if (isLoaded && categories.length === 0) {
    return (
      <ErrorContainer
        sticker={stickerLittleMoleSearch}
        text={"Меню заполняется..."}
        spanText={"Заходите позже!"}
      />
    );
  }

  return (
    <>
      <ProgressIndicator />
      <DeliveryAddress />
      <PopularProductList title={"🔥 Рекомендуем"} className={"mb-3"} />
      <ProductMenu {...props} />
    </>
  );
};

export default MenuPage;
