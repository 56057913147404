import React, { useEffect, useRef, useState } from "react";

import styles from "./OrderByTime.module.scss";
import {
  DAY_TITLE_MAP,
  getDatesArray,
  objLocaleDateOrderByTime,
  workTimeObjectToTitle,
} from "../../../utils/orderByTime";
import { ClockIcon } from "../../ui/icons/ClockIcon";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRestaurantSelector } from "../../../store/useSelectors";

const OrderByTime = ({ orderByTimeValue, setOrderByTimeValue }) => {
  const days = getDatesArray(7);

  const [open, setOpen] = useState(false);
  const [orderByTimeDay, setOrderByTimeDay] = useState(days[0]);
  const [orderByTimeTime, setOrderByTimeTime] = useState("");
  const inputRef = useRef(null);
  const { settings } = useRestaurantSelector();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.defaultValue = "";
    }
  }, []);

  useEffect(() => {
    if (orderByTimeTime !== "" && orderByTimeTime && orderByTimeDay) {
      setOrderByTimeValue(new Date(`${orderByTimeDay.value}T${orderByTimeTime}`));
    } else {
      setOrderByTimeValue(null);
    }

    if (inputRef?.current) {
      inputRef.current.defaultValue = "";
    }
  }, [orderByTimeDay, orderByTimeTime]);

  return (
    <>
      <button onClick={() => setOpen(!open)} className={styles.OrderByTime}>
        <ClockIcon style={{ marginRight: "0.5rem" }} />
        {orderByTimeValue
          ? ` ${orderByTimeValue.toLocaleString(undefined, objLocaleDateOrderByTime)}`
          : " Как можно скорее"}
      </button>
      <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        snapPoints={({ maxHeight }) => [0.45 * maxHeight, 0.65 * maxHeight]}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints);
        }}
      >
        <Container fluid>
          <div className={"mt-4 mb-3"}>
            <h6>День</h6>
            <Swiper slidesPerView={"auto"} centeredSlidesBounds={true} initialSlide={0}>
              {days.map((day, i) => (
                <SwiperSlide
                  style={{ width: "auto" }}
                  key={i}
                  onClick={() => setOrderByTimeDay(day)}
                  className={orderByTimeDay?.value === day.value ? "active" : ""}
                >
                  <Card
                    onClick={() => setOrderByTimeDay(day)}
                    style={{
                      border:
                        day.value === orderByTimeDay?.value
                          ? "1px solid var(--button-color)"
                          : null,
                      marginRight: "8px",
                    }}
                  >
                    <CardBody>{day.title}</CardBody>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={"mt-4 mb-3"}>
            <h6>Время</h6>
            <Input
              type="time"
              placeholder="Время"
              step={300}
              ref={inputRef}
              onChange={(event) => {
                setOrderByTimeTime(event.target.value);
              }}
              value={orderByTimeTime}
            />
            {Intl.DateTimeFormat().resolvedOptions().timeZone !== settings.timezone ? (
              <div className={"mt-2"} style={{ color: "var(--hint-color)" }}>
                <span>
                  ⚠️ Ваш часовой пояс ({Intl.DateTimeFormat().resolvedOptions().timeZone}), и
                  часовой пояс заведения ({settings.timezone}) расходятся. Указывайте время в вашем
                  часовом поясе.
                </span>
              </div>
            ) : null}
          </div>
          <div className={"mt-4 mb-3"}>
            <h6>График работы</h6>
            <ul>
              {Object.keys(settings.work_time)
                .filter((key) => settings.work_time[key])
                .map((key, idx) => (
                  <li key={idx}>
                    {DAY_TITLE_MAP[key]}: {workTimeObjectToTitle(settings.work_time[key])}
                  </li>
                ))}
            </ul>
          </div>
        </Container>
      </BottomSheet>
    </>
  );
};

export default OrderByTime;
