import React, { useEffect } from "react";
import { useRestaurantSelector } from "../store/useSelectors";
import { setThemeColors } from "../services/theme";
import {
  restaurantLocalStorageGetItem,
  restaurantLocalStorageSetItem,
} from "../utils/restaurantLocalStorage";
import { telegramSetupTheme } from "../services/telegram";

const Theme = ({ children }) => {
  const { restaurantId, settings } = useRestaurantSelector();

  useEffect(() => {
    if (!restaurantId) return;

    if (settings.colors === undefined && restaurantLocalStorageGetItem("colors")) {
      // Сначала берем из localStorage, чтобы у лоадера тоже цвет менялся
      setThemeColors(JSON.parse(restaurantLocalStorageGetItem("colors")));
    } else {
      telegramSetupTheme({});
      setThemeColors(settings.colors);
      restaurantLocalStorageSetItem("colors", JSON.stringify(settings.colors || {}));
    }
  }, [settings.colors]);

  return children;
};

export default Theme;
