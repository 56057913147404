import React from "react";
import DeliveryAddress from "../../components/DeliveryAddress/DeliveryAddress";
import { useRestaurantSelector } from "../../store/useSelectors";
import Loader from "../../components/Loader";
import { Container } from "reactstrap";

import styles from "./DeliverySpotsPage.module.scss";
import { telegramSendSoftTaptic } from "../../services/telegram";
import { useNavigate } from "react-router-dom";
import { useCheckoutActions } from "../../store/useActions";
import { restaurantLocalStorageSetItem } from "../../utils/restaurantLocalStorage";

const RestaurantSpotListPage = () => {
  const { spots } = useRestaurantSelector();
  const { setRestaurantSpot } = useCheckoutActions();
  const navigate = useNavigate();

  if (!spots) {
    return <Loader atCenter={true} />;
  }

  const onChangeRestaurantSpot = (item) => {
    telegramSendSoftTaptic();
    setRestaurantSpot(item);
    restaurantLocalStorageSetItem("restaurantSpotId", item.id);
    navigate("/");
  };

  return (
    <>
      <DeliveryAddress isChoose={true} />
      <div className={styles.restaurantSpots}>
        <Container className="h-100 pb-5" fluid>
          <h5 className={styles.restaurantSpots__title}>Заведения</h5>
          <div className={styles.restaurantSpots__body}>
            <div className={styles.restaurantSpots__items}>
              {spots &&
                spots.map((item) => {
                  return (
                    <article
                      onClick={() => onChangeRestaurantSpot(item)}
                      key={item.id}
                      className={styles.restaurantSpots__item}
                    >
                      <p>
                        {item.title}
                        <span style={{ color: "var(--hint-color)" }}>
                          <br />
                          {item.address.text}
                        </span>
                      </p>
                    </article>
                  );
                })}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default RestaurantSpotListPage;
