import { createSlice } from "@reduxjs/toolkit";

const CheckoutSlice = createSlice({
  name: "checkout",
  initialState: {
    deliveryType: null,
    paymentMethodOption: 0,
    deliveryAddress: null,
    phoneNumber: "",
    comment: "",
    tableNumber: null,
    restaurantSpot: null,
    addressInputValid: true,
    phoneNumberValid: true,
  },
  reducers: {
    setDeliveryType: (state, action) => {
      state.deliveryType = action.payload;
    },
    setPaymentMethodOption: (state, action) => {
      state.paymentMethodOption = action.payload;
    },
    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    setTableNumber: (state, action) => {
      state.tableNumber = action.payload;
    },
    setRestaurantSpot: (state, action) => {
      state.restaurantSpot = action.payload;
    },
  },
});

export default CheckoutSlice;
