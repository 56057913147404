import { createSlice } from "@reduxjs/toolkit";
import { getRestaurantIdParam } from "../../services/params";

const RestaurantSlice = createSlice({
  name: "restaurant",
  initialState: {
    restaurantId: getRestaurantIdParam(),
    settings: {},
    categories: [],
    featuredProducts: [],
    title: "",
    spots: [],
    tariff: {
      options: {
        BASE_LOADER: true,
      },
    },
    isLoaded: false,
    yandexMetrikaId: 91618917,

    restaurantNotFound: null,
  },
  reducers: {
    setRestaurant: (state, action) => {
      state.settings = action.payload.settings;
      state.categories = action.payload.categories.filter((c) => c.products.length > 0);
      state.tariff = action.payload.tariff;
      state.title = action.payload.title;
      state.spots = action.payload.spots;

      let featuredProducts = [];
      state.categories.forEach((category) => {
        if (category.products.length) {
          category.products.forEach((item) => {
            if (item.is_featured === true) {
              featuredProducts.push(item);
            }
          });
        }
      });
      state.featuredProducts = featuredProducts;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },

    setRestaurantNotFound: (state, action) => {
      state.restaurantNotFound = action.payload;
    },
  },
});

export default RestaurantSlice;
