import axios from "axios";
import { getRestaurantIdParam } from "../services/params";
import { telegramShowAlert } from "../services/telegram";
import { TCoreErrorCode } from "../data/consts";
import UserSlice from "../store/slices/user";
import { store } from "../store/store";
import RestaurantSlice from "../store/slices/restaurant";
import Config from "../data/Config";

export const coreClient = axios.create({
  baseURL: `${Config.CORE_URL}/api/v1/public/`,
  headers: {
    "X-Restaurant-Id": getRestaurantIdParam(),
  },
});

const processResponseError = (error) => {
  console.error(error);
  const { setRestaurantUserIsBlocked, setRestaurantUserNotFound, setUserNotFound } =
    UserSlice.actions;
  const { setRestaurantNotFound } = RestaurantSlice.actions;

  if (error.response && error.response.data) {
    if (typeof error.response.data.detail === "string" && error.response.data.code) {
      const errorCode = error.response.data.code;

      const errorCodeToActionMap = {
        [TCoreErrorCode.RESTAURANT_USER_BLOCKED]: () =>
          store.dispatch(setRestaurantUserIsBlocked(true)),
        [TCoreErrorCode.RESTAURANT_USER_NOT_FOUND]: () =>
          store.dispatch(setRestaurantUserNotFound(true)),
        [TCoreErrorCode.USER_NOT_FOUND]: () => store.dispatch(setUserNotFound(true)),
        [TCoreErrorCode.RESTAURANT_NOT_FOUND]: () => store.dispatch(setRestaurantNotFound(true)),
      };

      if (errorCodeToActionMap[errorCode]) {
        return errorCodeToActionMap[errorCode]();
      }

      return telegramShowAlert(error.response.data.detail);
    }
  }

  return telegramShowAlert("Произошла неизвестная ошибка");
};

export const coreGetCurrentRestaurant = async () => {
  try {
    return await coreClient.get("/restaurants/current");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreTelegramUserAuth = async (initData) => {
  try {
    return await coreClient.post(`/users/telegram-auth`, { init_data: initData });
  } catch (error) {
    processResponseError(error);
  }
};
export const coreGetCart = async (params) => {
  const urlParams = new URLSearchParams(params);
  try {
    return await coreClient.get(`/cart/?${urlParams}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreAddCartItem = async (productId, quantity, options) => {
  try {
    return await coreClient.post("/cart/", {
      product_id: productId,
      quantity: quantity,
      options: options,
    });
  } catch (error) {
    processResponseError(error);
  }
};

export const coreSetCartItemQuantity = async (item_id, quantity) => {
  try {
    return await coreClient.patch("/cart/", {
      item_id: item_id,
      quantity: quantity,
    });
  } catch (error) {
    processResponseError(error);
  }
};

export const corePatchCartPromocode = async (value) => {
  try {
    return await coreClient.patch("/cart/promocode", {
      code: value,
    });
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantUser = async () => {
  try {
    return await coreClient.get("/restaurants/users/me");
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateOrder = async ({
  paymentMethod,
  deliveryType,
  phoneNumber,
  comment,
  deliveryAddressId,
  tableNumber,
  bonuses,
  restaurantSpotId,
  by_time,
}) => {
  try {
    return await coreClient.post("/orders/", {
      payment_method: paymentMethod,
      delivery_type: deliveryType,
      phone_number: phoneNumber,
      comment: comment,
      delivery_address_id: deliveryAddressId,
      table_number: tableNumber,
      bonuses: bonuses,
      restaurant_spot_id: restaurantSpotId,
      by_time: by_time,
    });
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetOrder = async ({ id, expanded = true }) => {
  try {
    return await coreClient.get(`/orders/${id}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetOrderList = async (page) => {
  try {
    return await coreClient.get(`/orders/my?page=${page}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateOrderPaymentlink = async (id) => {
  try {
    return await coreClient.post(`/orders/${id}/payment-link`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetAddressSuggestions = async (query) => {
  try {
    return await coreClient.get(`/addresses/suggestions?q=${query}`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantDeliveryAreas = async () => {
  try {
    return await coreClient.get(`/restaurants/settings/delivery-areas`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreCreateRestaurantUserDeliveryAddress = async (data) => {
  try {
    return await coreClient.post(`/addresses/delivery`, data);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreGetRestaurantUserDeliveryAddresses = async () => {
  try {
    return await coreClient.get(`/addresses/delivery`);
  } catch (error) {
    processResponseError(error);
  }
};

export const coreDeleteRestaurantUserDeliveryAddress = async (id) => {
  try {
    return await coreClient.delete(`/addresses/delivery/${id}`);
  } catch (error) {
    processResponseError(error);
  }
};
