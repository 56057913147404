import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import { useRestaurantSelector, useUISelector } from "../store/useSelectors";
import { useUIActions } from "../store/useActions";
import { Container, Row } from "reactstrap";
import { telegramSelectionChanged } from "../services/telegram";

const CategoriesSwiperSkeleton = () => (
  <div className="categories-skeleton">
    {[
      ...new Array(6).map((_, index) => (
        <div key={index} className="categories-skeleton-title"></div>
      )),
    ]}
  </div>
);

const CategoriesNavigation = (props) => {
  const { currentCategory } = useUISelector();
  const { setCurrentCategory } = useUIActions();
  const { categories } = useRestaurantSelector();
  const [swiper, setSwiper] = useState(null);
  const navbarRef = useRef();
  const rowRef = useRef();

  const selectCategory = (categoryId) => {
    setCurrentCategory(categoryId);
    telegramSelectionChanged();

    const a = document.querySelector(`div[data-category-id="${categoryId}"]`);
    window.scroll({
      top: a.offsetTop - window.screen.height * 0.1,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const rectRow = rowRef.current.getBoundingClientRect().top;

      if (rectRow < -100) {
        navbarRef.current.style.top = "-100px";
        navbarRef.current.style.position = rectRow < -100 ? "fixed" : "static";
        if (rectRow < -200) {
          navbarRef.current.style.top = "0px";
          navbarRef.current.style.position = "fixed";
        }
      } else {
        navbarRef.current.style.top = "-100px";
        navbarRef.current.style.position = "static";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (categories.length > 0) setCurrentCategory(categories[0].id);
  }, [categories]);

  useEffect(() => {
    if (!swiper) return;
    var slide = 0;
    categories.forEach((category, idx) => {
      if (category.id === currentCategory) {
        slide = idx;
      }
    });
    swiper.slideTo(slide);
  }, [currentCategory]);

  return (
    <Container fluid>
      <Row>
        <div ref={rowRef} style={{ height: 64, marginLeft: "calc(var(--bs-gutter-x)* -.5)" }}>
          <div
            ref={navbarRef}
            className={"content categories w-100"}
            style={{ background: "var(--bg-color)", position: "static", transition: "top 0.6s" }}
          >
            {!props.loaded ? (
              <CategoriesSwiperSkeleton />
            ) : (
              <Swiper
                slidesPerView={"auto"}
                style={{ paddingRight: "16px", paddingLeft: "16px" }}
                centeredSlidesBounds={true}
                onSwiper={setSwiper}
                initialSlide={0}
                cssMode={true}
              >
                {categories.map((category, i) => (
                  <SwiperSlide
                    style={{ width: "auto" }}
                    key={i}
                    onClick={() => selectCategory(category.id)}
                    className={currentCategory === category.id ? "active" : ""}
                  >
                    {category.title}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};
export default CategoriesNavigation;
