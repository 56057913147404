export const getDatesArray = (numberOfDays) => {
  const result = [];

  const days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  const months = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];

  const today = new Date();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${date.getFullYear()}-${month}-${day}`;
  };

  const formatDayTitle = (date, index) => {
    const dayOfWeek = days[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    if (index === 0) return "Сегодня";
    if (index === 1) return "Завтра";
    return `${dayOfWeek}, ${day} ${month}`;
  };

  for (let i = 0; i < numberOfDays; i++) {
    const date = new Date();
    date.setDate(today.getDate() + i);
    result.push({
      title: formatDayTitle(date, i),
      value: formatDate(date),
    });
  }

  return result;
};

export const DAY_TITLE_MAP = {
  sun: "Воскресенье",
  mon: "Понедельник",
  tue: "Вторник",
  wed: "Среда",
  thu: "Четверг",
  fri: "Пятница",
  sat: "Суббота",
};

export const workTimeObjectToTitle = (workTimeObject) => {
  const toTime = (v) =>
    `${String(Math.trunc(v / 60)).padStart(2, "0")}:${String(v % 60).padStart(2, "0")}`;

  return `с ${toTime(workTimeObject[0])} до ${toTime(workTimeObject[1])}`;
};

export const objLocaleDateOrderByTime = {
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};
